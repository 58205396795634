<mat-form-field class="w-full">
    <mat-label>{{ label() }}</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="control()" [max]="max()" [placeholder]="placeholder()">
    @if (clearButton()) {
        <button mat-icon-button [class.invisible]="control().value == null" matIconSuffix (click)="control().reset(null)">
            <mat-icon>clear</mat-icon>
        </button>
    }
    <mat-datepicker-toggle matIconSuffix [for]="picker"/>
    <mat-datepicker #picker/>
</mat-form-field>
