import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { LocalDate } from "@js-joda/core";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from "@angular/material/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconButton } from "@angular/material/button";

@Component({
    selector: 'app-date-field',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatDatepicker,
        MatDatepickerInput,
        MatDatepickerToggle,
        TranslateModule,
        MatIconButton,
    ],
    templateUrl: './date-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "class": "block",
    }
})
export class DateFieldComponent {

    label = input.required<string>();
    control = input.required<FormControl<LocalDate | null>>();
    max = input<LocalDate | null>(null);
    placeholder = input<string>("");
    clearButton = input<boolean>(false);
}
